import React, { useState } from 'react';
import { Button } from '../../components/';
import styles from '../Main/Main.module.css';
import oli from '../../olive.jpg';

export function Knowledge() {
  const [olive, setOlive] = useState(false);
  const handleButtonClick = () => {
    setOlive(!olive);
  };

  return (
    <div className={styles.pagewrapper}>
      <div className={styles.textwrapper}>
        <h1>Knowl</h1>

        <Button onClick={handleButtonClick}>Nolej</Button>
        {olive && <img src={oli} alt="where'd he go?" title="bonjour"
            style={{ height: '150px', width: 'auto', display: 'flex'}} />}

        <h1>edge</h1>
      </div>
    </div>
  );
}